import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDimensions } from 'react-native-web-hooks';
import { useLoadOnIdle } from 'app-libs/hook_modules/lazyLoad';

import { Colors, StyleSheet, View } from '@dkrm/ui-kit-basic';
import { Close } from '@dkrm/icons';
import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';
import cStyles from '@dkrm/general-libs/theme/styles';

import {
  getAdsByLocation,
  getShouldShowFloatingIcon,
} from 'app-libs/redux_modules/selectors/advertisement';
import { closeFloatingIcon } from 'app-libs/redux_modules/entity_modules/advertisement';
import { getFirstLottieDataFromAdvertisements } from 'app-libs/etc/advertisementHelper';
import { withError } from 'app-libs/components/ErrorBoundary';

import { getPathFromUrl, getHostnameFromUrl } from 'app-libs/etc/routeHelper';
import { trackClickedFloatingIcon } from 'app-libs/trackers';

import { K__STOREFRONT__HOMEPAGE_AND_PRODUCT_LIST__FLOATING_ICON } from 'constants/advertisementConstants';
import { K_DKRMA_HOSTNAME } from 'constants/routeConstants';

const K_FLOATING_ICON_WIDTH_PROPORTION = 100;
const K_FLOATING_ICON_HEIGHT_PROPORTION = 100;
const K_DEFAULT_WIDTH = 360;

const FloatingIcon = React.memo(() => {
  const dispatch = useDispatch();
  const { window } = useDimensions();
  const [isDataReady, setIsDataReady] = useState(false);

  const advertisements = useSelector((state) =>
    getAdsByLocation(
      state,
      K__STOREFRONT__HOMEPAGE_AND_PRODUCT_LIST__FLOATING_ICON,
    ),
  );
  const shouldShowFloatingIcon = useSelector(getShouldShowFloatingIcon);
  const trackClickFloatingIcon = useCallback(() => {
    trackClickedFloatingIcon();
  }, []);

  const closeIcon = useCallback(() => {
    dispatch(closeFloatingIcon());
  }, [dispatch]);

  const {
    lottieAnimationUrl,
    lottieToUrl,
  } = getFirstLottieDataFromAdvertisements(advertisements);

  const isShowingFloatingIcon =
    shouldShowFloatingIcon &&
    Boolean(lottieAnimationUrl) &&
    Boolean(lottieToUrl);

  const [Lottie] = useLoadOnIdle(() => import('react-lottie'), {
    shouldPreventLoad: !isShowingFloatingIcon,
  });

  const windowWidth = window?.width || K_DEFAULT_WIDTH;
  // @note(dika) 9 Dec 2021: Adjust according to width
  // Reason: Main banner has responsible height
  const marginTop = 0.8 * windowWidth - 25;

  const shouldUseAnchor = getHostnameFromUrl(lottieToUrl) === K_DKRMA_HOSTNAME;

  if (!isShowingFloatingIcon || !Lottie) {
    return null;
  }

  return (
    <View style={[s.container, { top: marginTop }]}>
      <Button
        to={getPathFromUrl(lottieToUrl)}
        onPress={trackClickFloatingIcon}
        linkProps={{
          isUsingAnchor: shouldUseAnchor,
        }}
      >
        {() => (
          <Lottie
            options={{
              loop: true,
              path: lottieAnimationUrl,
            }}
            isClickToPauseDisabled
            height={K_FLOATING_ICON_WIDTH_PROPORTION}
            width={K_FLOATING_ICON_HEIGHT_PROPORTION}
            eventListeners={[
              {
                eventName: 'data_ready',
                callback: () => setIsDataReady(true),
              },
            ]}
          />
        )}
      </Button>
      {isDataReady && (
        <View style={s.closeButtonContainer}>
          <View style={s.closeButton} onClick={closeIcon}>
            <Close size={16} style={s.icon} />
          </View>
        </View>
      )}
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    position: 'fixed',
    right: 8,
    zIndex: 100,
  },
  closeButtonContainer: {
    top: 0,
    right: 0,
    ...cStyles.abs,
  },
  closeButton: {
    width: 24,
    height: 24,
    top: 0,
    right: 0,
    borderRadius: '100%',
    ...cStyles.bgw,
    ...cStyles.abs,
  },
  icon: {
    color: Colors.C_BLACK_60,
    ...cStyles.mts,
    ...cStyles.mls,
  },
});

export default withError(FloatingIcon);
